import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

const config = {
  apiKey: 'AIzaSyD6uz631e3vzPmGV3IS6Z8nVcxKxGk7o3M',
  databaseURL: 'https://origin-270811.firebaseio.com',
  projectId: 'origin-270811',
  storageBucket: 'origin-270811.appspot.com',
};

const app = firebase.initializeApp(config);
export default app;

export const firestore = app.firestore();
export const { FieldValue } = firebase.firestore;

export const storage = app.storage();

export const functions = app.functions('europe-west1');
