import React, { useState } from 'react';

import { firestore, FieldValue } from '../firebase';

import { Uploader } from './Uploader';

import './App.css';

const Column = ({ title, blocks, onChange }) => (
  <div className="column">
    <h2>{title}</h2>
    {blocks.map((block, index) =>
      block === null ? null : (
        <div key={index} className="block">
          <textarea
            rows={(block.match(/\n/g) || []).length + 1}
            value={block}
            onChange={e => {
              const newBlocks = [...blocks];
              newBlocks[index] = e.target.value;
              onChange(newBlocks);
            }}
          />
          <button
            onClick={() => {
              const newBlocks = [...blocks];
              newBlocks[index] = null;
              onChange(newBlocks);
            }}
          >
            X
          </button>
        </div>
      )
    )}
  </div>
);

const PageInput = ({ page, onChange, label, field }) => (
  <div className="page-input">
    <label htmlFor={field}>{label}</label>
    <input
      id={field}
      value={page[field]}
      onChange={e => {
        onChange({ ...page, [field]: e.target.value });
      }}
    />
  </div>
);

const Page = ({ page, onChange }) => {
  const { columns } = page;

  return (
    <div className="page">
      <h1>Scanned Page</h1>
      <PageInput page={page} onChange={onChange} label="Book" field="book" />
      <PageInput page={page} onChange={onChange} label="Category" field="category" />
      <PageInput page={page} onChange={onChange} label="Page number" field="number" />
      <PageInput page={page} onChange={onChange} label="Page side" field="side" />
      <div className="columns">
        <Column
          title="Left column"
          blocks={columns[0]}
          onChange={blocks => onChange({ ...page, columns: [blocks, columns[1], columns[2]] })}
        />
        <Column
          title="Middle column"
          blocks={columns[1]}
          onChange={blocks => onChange({ ...page, columns: [columns[0], blocks, columns[2]] })}
        />
        <Column
          title="Right column"
          blocks={columns[2]}
          onChange={blocks => onChange({ ...page, columns: [columns[0], columns[1], blocks] })}
        />
      </div>
    </div>
  );
};

export const App = () => {
  const [file, setFile] = useState(null);
  const [pages, setPages] = useState(null);

  const reset = () => {
    setFile(null);
    setPages(null);
  };

  return (
    <>
      <Uploader
        className="uploader"
        onStart={reset}
        onComplete={(f, p) => {
          setFile(f);
          setPages(p);
        }}
      />
      {!pages && (
        <div className="guidelines">
          <h1>Scanning Guidelines</h1>
          <ul>
            <li>Make sure the camera is oriented correctly.</li>
            <li>Take the picture as straight as possible.</li>
            <li>Keep the full page (including page numbers) in view.</li>
            <li>Prevent other texts appearing outside of the page(s).</li>
          </ul>
        </div>
      )}
      <div className="pages">
        {pages &&
          pages.map((page, index) => (
            <Page
              key={index}
              page={page}
              onChange={newPage => {
                const newPages = [...pages];
                newPages[index] = newPage;
                setPages(newPages);
              }}
            />
          ))}
      </div>
      {pages && (
        <button
          className="submit"
          onClick={() => {
            (async () => {
              for (const page of pages) {
                const bookIdentifier = page.book.replace(/\W/g, '_').toLowerCase();
                const ref = firestore
                  .collection('monocle')
                  .doc(bookIdentifier)
                  .collection('pages')
                  .doc(`${page.number}`);
                const data = { import: { date: FieldValue.serverTimestamp(), file }, ...page };
                data.columns = data.columns.map(blocks => ({ blocks: blocks.filter(b => b !== null) }));
                await ref.set(data);
              }
              reset();
            })();
          }}
        >
          Submit
        </button>
      )}
    </>
  );
};
