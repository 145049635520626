import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';

import { storage, functions } from '../firebase';

export const Uploader = ({ onStart = () => {}, onComplete = () => {}, className }) => {
  const [state, setState] = useState();

  const onDrop = useCallback(
    files => {
      if (!files || files.length === 0) return;
      (async () => {
        try {
          const [file] = files;
          const generatedFileName = `${uuidv4()}.jpg`;
          onStart();
          setState({ task: `Uploading ${generatedFileName}` });

          const storagePath = `monocle/${generatedFileName}`;
          const reference = storage.ref(storagePath);
          const uploadTask = reference.put(file);

          uploadTask.on('state_changed', snapshot => {
            setState({ task: 'Uploading photo...', progress: snapshot.bytesTransferred / snapshot.totalBytes });
          });

          await uploadTask;

          setState({ task: 'Recognizing text...' });
          const { data: pages } = await functions.httpsCallable('extractMonoclePage')({ storagePath });
          onComplete(storagePath, pages);

          setState(null);
        } catch (err) {
          setState({ error: err.message });
          await new Promise(resolve => setTimeout(resolve, 10 * 1000));
          setState(null);
        }
      })();
    },
    [onStart, onComplete]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    multiple: false,
    disabled: !!state,
    onDrop,
  });

  return (
    <button
      {...getRootProps({
        className: `${className}${isDragActive ? ' drag' : ''} `,
        disabled: !!state,
      })}
    >
      <input {...getInputProps()} />
      {state ? (
        <>
          <div className={`state${state.error ? ' error' : ''}`}>
            {state.error ? `ERROR: ${state.error}` : state.task}
          </div>
          {'progress' in state && <div className="state">{Math.floor(state.progress * 100)}%</div>}
        </>
      ) : (
        <>Click or drop file here to upload...</>
      )}
    </button>
  );
};
